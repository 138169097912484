import React from "react"
import { Helmet } from "react-helmet"
import Favicon from '../images/favicon.png'

const Head= (props) => {
  const title       = props.title || 'Entrepreneur Gains';
  const description = props.description || 'Host Sabby Choudhary interviews Y-Combinator (YC) alumni in his podcast Entrepreneur Gains, uncovering the raw startup stories that preceded their entrepreneurial successes or failures.';
  const favicon     = Favicon;
  const image       = props.image || 'https://entrepreneurgains.com/card.png'  //`https://entrepreneurgains.com/${props.image || 'card.png'}` //Card;
  const url         = 'https://entrepreneurgains.com';
  
  return <Helmet
    htmlAttributes={{
      lang: "en-us",
    }}
  >
    <title>{title}</title>
    <link rel="icon"                 href={favicon} />
    <meta name="description"         content={description} />

    <meta name="twitter:card"        content="summary" />
    <meta name="twitter:site"        content="@sabbyiam" />
    <meta name="twitter:title"       content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image"       content={image} />

    <meta property="og:title"        content={title} />
    <meta property="og:url"          content={url} />
    <meta property="og:image"        content={image} />
  </Helmet>
}

export default Head
