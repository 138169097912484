import React from "react"
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import SabbyLogo from '../images/sabby-logo.png'

const Container = styled.footer`
  margin-top: 10px;
  svg {
    padding-right: 5px;
  }
  .author {
    display: flex;
    align-items: center;
  }
  .author img  {
    padding-right: 12px;
    height: 24px;
  }
  .author a {
    color: white;
    text-decoration: none;
  }
  .author:hover a {
    text-decoration: underline;
  }
  
`

const Separator = styled.div`
  width: 95%;
  border-bottom: 1px dotted #41434c;
  margin: 30px auto;
  display: block;
`;


const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-bottom: 48px;
  strong {
    font-size: 18px;
  }
  div {
    min-width: 240px;
    padding: 0px 10px;
  }
  ul {
    list-style: none;
    padding-inline-start: 0;
    margin-block-start: 0.5em;
  }
  li {
    padding: 3px 0;
  }
  li a {
    cursor: pointer;
    text-decoration: none;
    color: white;
    font-size: 16px;
  }
  li:hover {
    text-decoration: underline;
  }
  .contact a {
    font-size: 16px;
  }
  @media (max-width: 768px) {
    display: block;
    div {
      padding: 20px 0;
      margin: auto auto;
      width: min-content;
    }
    li {
      padding: 5px 0;
    }
  }
`

const Copyright = styled.div`
  text-align: center;
  color: #7D7F87;
  font-size: 12px;
  padding: 10px 0;
`

const Footer = () => (
  <Container>
    <Separator />
    <Content>
      <div className="author">
        <a href="https://www.sabbychoudhary.com" target="_blank" rel="noopener noreferrer">
          <img src={SabbyLogo}  alt="Sabby Avatar"/> 
        </a>
        <a href="https://www.sabbychoudhary.com" target="_blank" rel="noopener noreferrer">
          <strong>
            Sabby
          </strong>
        </a>
      </div>
      <div>
        <strong>
          Podcast
        </strong>
        <ul>
          <li>
            <a href="https://www.youtube.com/channel/UC939WeUMJxe6aCFzwOWRjKA" target="_blank" rel="noopener noreferrer">Youtube</a>
          </li>
          <li>
            <a href="https://open.spotify.com/show/0Z2ja9B2DqmC4hapJyfnyA?si=Oml4tCBtRaeYRiYEKjHMGA" target="_blank" rel="noopener noreferrer">Spotify</a>
          </li>
          <li>
            <a href="https://podcasts.apple.com/podcast/id1523944316?ct=podlink&mt=2&app=podcast&ls=1" target="_blank" rel="noopener noreferrer">iTunes</a>
          </li>
        </ul>
      </div>
      <div className="contact">
        <strong>
          Keep in touch
        </strong>
        <ul>
          <li>
          
            <a href="mailto:contact@entrepreneurgains.com" target="_blank" rel="noopener noreferrer" className="email">
              <FontAwesomeIcon icon={faEnvelope}  size="sm" />contact@entrepreneurgains.com
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/sabbyiam/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} />Sabbyiam
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/sabbychoudhary/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedinIn} />SabbyChoudhary 
            </a>
          </li>
        </ul>
      </div>

    </Content>
    <Copyright>
      Sabby Choudhary © {new Date().getFullYear()} All rights Reserved.
    </Copyright>
  </Container>
)

export default Footer