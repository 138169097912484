/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
import '../styles/global.css'
import styled from 'styled-components'

import Head from './head'
import Header from "./header"
import Footer from './footer'

import LinesBG from '../images/full-background.png'

const Container = styled.div`
  background-color: #1d1f2a;
  color: #fff;
  font-size: 18px;
  overflow-x: hidden;
`

const Content = styled.main`
  background: url(${LinesBG});
  background-repeat: space;
    background-size: cover;
`

const Layout = ({title, description, image, children }) => {
  return (
    <Container>
      <Head
        title={title}
        description={description}
        image={image}
      />
      <Header/>
      <Content>
        {children}
      </Content>
      <Footer />
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
