import { Link } from "gatsby"
import React from "react"
import styled from 'styled-components'
import SabbyAvatar from '../images/sabby-avatar.png'
import EgLogo from '../images/eg-logo.svg'
import WhoIsGif from '../images/who-is.gif'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2% 3%;
  max-width: 1400px;
  margin: 0 auto;
`

const Title = styled.div`
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
  
  a {
    text-decoration: none;
    color: white;
    cursor: pointer;
  }

  .eg-logo img {
    width: 60px;
    height: 60px;
  }

  @media (max-width: 768px) {
    display: flex;
    font-size: 18px;
  }

  @media (max-width: 380px) {
    .eg-logo img {
      width: 48px;
      height: 48px;
    }
  }
`

const Author = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  margin-left: auto;

  @keyframes text-slide {
    0% { transform: translateX(200px) }
    50% { transform: translateX(-25px) }
    100% { transform: translateX(-20px) }
  }

  a {
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }

  a:hover img {
    border-color: #ffd664;
    transition: 1s;
  }
  
  a:hover p {
    animation: text-slide .6s ease 0s forwards;
  }

  .author-img {
    background: #1d1f2a;
    border: 1px solid #494D5F;
    border-radius: 100%;
    width: 60px;
    height: 60px;
    z-index: 3;
    transition: .2s ease;
  }

  p {
    position: relative;
    color: #ffd664;
    transform: translateX(200px);
    z-index: 1;
  }

  .sliding-effect {
    position: absolute;
    right: -180px;
    width: 200px;
    height: 60px;
    background: #1d1f2a;
    z-index: 2;
  }

  .gif {
    width: 100px;
    align-self: flex-end;
    margin-right: 2%;
  }

  @media (max-width: 380px) {
    .author-img {
      width: 48px;
      height: 48px;
    }
  }
`

const Header = () => (
  <header>
    <Container>
      <Title>
        <Link className="eg-logo" to="/"><img src={EgLogo} alt="Website Logo"/></Link>
      </Title>      
      <Author>
        <a href="https://sabbychoudhary.com" target="_blank" rel="noopener noreferrer">
          <div className="sliding-effect"></div>
          <p>sabbychoudhary.com</p>
          <img className="author-img" src={SabbyAvatar} alt="pointing sabby website" />
        </a>
        <img className="gif" src={WhoIsGif} alt="Sabby" />
      </Author>
    </Container>
      
  </header>
)

export default Header
